// Disable site in iframe
window.isIfr = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

if (isIfr()) {
  setTimeout(() => {
    document.body.innerHTML = '';
  }, 0);
}

$(function() {
	/* -------------------- bxSlider ---------------------------- */

	$(document).ready(function () {
		var windowWidth = parseInt(jQuery(window).width());
		if (windowWidth > 767) {
		    $('.bxSlider').bxSlider({
		    	// adaptiveHeight: true,
		        auto: true,
		        autoStart:true,
				autoControls:false,
				stopAuto:false,
		        autoHover: true,
		        controls: false,
		    	default: 1000,
		        easing: 'ease-in-out',
			    minSlides: 2,
			    maxSlides: 2,
		        moveSlides: 1,
		        responsive: true,
			    slideMargin: 10,
		    	slideWidth: 360,
		        useCSS: true,
		    });
		} else {
				$('.bxSlider').bxSlider({
		    	// adaptiveHeight: true,
		        auto: false,
		        autoStart:true,
				autoControls:false,
				stopAuto:false,
		        autoHover: true,
		        controls: false,
		    	default: 1000,
		        easing: 'ease-in-out',
		        moveSlides: 1,
		        responsive: true,
			    slideMargin: 10,
		        useCSS: true,
	    	});
		}
	});

	/* -------------------- Toggle Topmenu ---------------------------- */

	$('.toggle-menu').click(function() {
		$(this).toggleClass('active');
		if ($('.header__nav').css('display') == 'none') {
			$('.header__nav').show().addClass('is-show-nav');
			$('.header').addClass('is-show');
		} else {
            if (currentDropdown) {
                dropdownHide(currentDropdown);
            }
			$('.header__nav').hide().removeClass('is-show-nav');
			$('.header').removeClass('is-show');
		}
		return false;
	});

	/* -------------------- Menu ---------------------------- */

	var k = 8;
	$('nav .nav__menu > li > a').each(function() {
		$(this).css('z-index', k);
		--k;
	});

	$('nav ul > li > a').each(function() {
		if ($(this).attr('href') == window.location.pathname) {
			$(this).addClass('active');
			$(this).css('z-index', '11');
		}
	});

	/* -------------------- Mobile Menu ---------------------------- */

	var toggles = document.querySelectorAll(".c-hamburger");

	for (var i = toggles.length - 1; i >= 0; i--) {
		var toggle = toggles[i];
		toggleHandler(toggle);
	};

	function toggleHandler(toggle) {
		toggle.addEventListener( "click", function(e) {
			e.preventDefault();
			(this.classList.contains("is-active") === true) ? this.classList.remove("is-active") : this.classList.add("is-active");
		});
	}

	/* -------------------- Follow Us ---------------------------- */

	$('#fl-btn').click(function() {
		$('.follow-us .texts *').fadeToggle("fast").delay(800).fadeToggle("fast");
		if ($(document).width() < 490) {
			$('.follow-us .texts').delay(400).animate({
				height: 'toggle'
			});
		} else {
			$('.follow-us .texts').delay(400).animate({
				width: 'toggle'
			});
		}
		return false;
	});

	/* -------------------- Scroll Left List ---------------------------- */

	$('#scroll-btn').click(function() {
		$('.sidebar-menu__menu_scroll').slideToggle('slow');
		$('#scroll-btn').toggleClass("button-link_scroll-show");
		return false;
	});

	/* -------------------- To-top link Click ---------------------------- */

    $('.totop-link').click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

	/* -------------------- FAQ + MoneyBack + Pricing List ---------------------------- */

	$.easing.def = "easeInOutBack";


	$('#money-btn li a[href="#"]').click(function(e) {

		$(this).toggleClass('active-btn');
		var dropDown = $(this).next();

		$('#money-btn li p').not(dropDown).slideUp('slow');
		$('#money-btn li a[href="#"]').not($(this)).removeClass('active-btn');
		dropDown.slideToggle('slow');

		e.preventDefault();
	});
	$('#pricing-btn li a[href="#"]').click(function(e) {

		$(this).toggleClass('active-btn');
		var dropDown = $(this).next();

		$('#pricing-btn li div').not(dropDown).slideUp('slow');
		$('#pricing-btn li a[href="#"]').not($(this)).removeClass('active-btn');
		dropDown.slideToggle('slow');

		e.preventDefault();
	});


	/* -------------------- Table Vertical Hover ---------------------------- */

	$("#center .pricing-start .table-striped").delegate('td',
		'mouseover mouseleave', function(e) {
			if (e.type === 'mouseover') {
				$("colgroup").eq($(this).index()).addClass("hover");
			} else {
				$("colgroup").eq($(this).index()).removeClass("hover");
			}
		});

	/* -------------------- Smartphone Scroll Menu ---------------------------- */

	var nav_width = $('.width-navigation').width();
	var nav_window = $(window).width();
	var right_limit = -((nav_width + 80) - nav_window);
	var step;

	$('.nav-button-left').click(function(e) {

		step = $('.width-navigation').offset().left + 90;

		if ($('.width-navigation').offset().left > -41) {
			step = 40;
		}

		$('.width-navigation').animate({
			'left': step
		}, 500);
		e.preventDefault();
	});

	$('.nav-button-right').click(function(e) {
		step = $('.width-navigation').offset().left - 90;

		if ($('.width-navigation').offset().left < right_limit + 40) {
			step = right_limit;
		}

		$('.width-navigation').animate({
			'left': step
		}, 500);
		e.preventDefault();
	});
	var popolam = $(window).width() / 2;
	var popolam_object = $('.active').width() / 3;

	popolam = popolam - popolam_object;

	if ($('.width-navigation li a').is('.active')) {
		var aaa = -($('.active').offset().left - popolam);
	}

	if (aaa > 20) {
		aaa = 40;
	}
	if (aaa < right_limit + 20) {
		aaa = right_limit;
	}
	if ($('.active')) {
		$('.width-navigation').css({
			'left': aaa
		});
	}

	$(window).resize(function() {
		nav_width = $('.width-navigation').width();
		nav_window = $(this).width();
		right_limit = -((nav_width + 80) - nav_window);



		if ($('.width-navigation li a').is('.active')) {
			if (aaa > 20) {
				aaa = 40;
			}
			$('.width-navigation').css({
				'left': aaa
			});
		}
	});

if ($.fn.uvoAutocomplete) {
	//add class "selected" for item select
	$('.place-your-order select').uvoAutocomplete({
		sortBy: 'none',
		onOpen: function(e) {
			var lebelEl = this.label;
			$(this._listElement.children()).each(function() {
				if ($(this).text() == lebelEl) {
					$(this).addClass('selected')
				}
			});
		},
		sortBy: 'none'
	});
}

	//for inquiry page
	$('.show-hide').find('.hidden').hide();
	$('#inq-done-more').click(function() {
		if (!$(this).hasClass('less')) {
			$(this).parents('.show-hide').find('.hidden').slideDown(500);
			$(this).text('Read less').addClass('less');
		} else {
			$(this).parents('.show-hide').find('.hidden').slideUp(500);
			$(this).text('Read more').removeClass('less');
		}
	});

	$('.plagcheckc aside#rightplagpanel').clone().appendTo('.plagcheckc').addClass(
		'rgt-spec-plag').attr('id', 'rightplagpanel_2');

    var headerTop = $("#js-header-top"),
        headerTopHeight = headerTop.outerHeight();

    function fixHeader() {
        if ($(window).width() > 1024) {
            if ($(window).scrollTop() > (headerTopHeight + 50)) {
                $("body").css("padding-top", headerTopHeight);
                $("body").addClass("header-fixed__enabled");
                headerTop.addClass("header-top--fixed");
            } else {
                disableFixedHeader();
            }
        } else {
            disableFixedHeader();
        }
    }

    function disableFixedHeader() {
        if (headerTop.hasClass("header-top--fixed")) {
            $("body").css("padding-top", 0);
            $("body").removeClass("header-fixed__enabled");
            headerTop.removeClass("header-top--fixed");
        }
    }

    window.addEventListener("scroll", fixHeader);
    window.addEventListener("load", fixHeader);
    window.addEventListener("resize", fixHeader);


    // nav dropdown

    var currentDropdown,
        scrollStartingPoint;

    function navDropdownActivate() {
        $(".nav__menu").on("click", ".nav__item--dropdown > a", function(e) {
            e.preventDefault();
            e.stopPropagation();

            if ($(this).parent().hasClass("active")) {
              $(".nav__item--dropdown.active").removeClass("active");
            } else {
              $(".nav__item--dropdown.active").removeClass("active");
              dropdownShow($(this));
            }
        }).on("click", ".dropdown-nav", function(e) {
            e.stopPropagation();
        });
    }

    navDropdownActivate();
    window.addEventListener("resize", navDropdownActivate);
    window.addEventListener("scroll", function() {
        if (currentDropdown && $(window).width() > 767) {
            if ($(window).scrollTop() > scrollStartingPoint + 150
                || $(window).scrollTop() < scrollStartingPoint - 150) {
                dropdownHide($(currentDropdown));
            }

        }
    });

    var outsideClickHandler = function() {
        dropdownHide($(currentDropdown));
    };

    function dropdownShow(el) {
        currentDropdown = el;
        $(el).parent().addClass("active");

        $(document).bind("click", outsideClickHandler);

        scrollStartingPoint = $(window).scrollTop();
    }

    function dropdownHide(el) {
        currentDropdown = null;
        scrollStartingPoint = null;

        $(el).parent().removeClass("active");

        $(document).unbind("click", outsideClickHandler);
    }


    $(".more-services-btn").click(function() {
        $(this).toggleClass("active");
        $(".footer-nav__menu_menu2").slideToggle(450);
    });
});

